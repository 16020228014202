

<template>
  <div class="Work_I">
    <div class="headNavs">
      <div class="headTilte">工作汇报</div>
      <div class="headDetails">说明：这里可以管理线上平台销售的订单与线下个人创建的全部订单</div>
    </div>

    <div class="capitalNav">
      <div style="display:flex;justify-content: space-between;">
        <div class="NavLi">
          <div class="NavLiName">汇报对象：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.to_staff_id" placeholder="选择汇报对象" style="width:220px">
              <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">汇报类型：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.log_type" placeholder="汇报类型">
              <el-option v-for="item in logType" :key="item.value" :label="item.content" :value="item.value"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName pp" @click="ReportClick()">管理汇报类型</div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">提交人：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.staff_id" placeholder="选择提交人" >
              <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi" v-for="obj in 6" :key="obj"></div>
      </div>
      <div style="margin-top:20px">
        <div class="NavLi">
          <div class="NavLiName">创建时间：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份"></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份"></el-date-picker>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>


    <div class="TablesList">
      <div class="TablesADD">
        <div style="width:150px;margin-right:24px">
          <el-select v-model="type" placeholder="请选择" @change="Chang_ses">
            <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select> 
        </div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建汇报</div>
        <div class="NavLiDelte" v-if="type == 20" @click="Add_Del()"><i class="el-icon-delete"></i>批量删除</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入提交人">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="Selection">
        <el-table-column type="selection" align="center" v-if="type == 20"></el-table-column>
        <el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column prop="staff.realname" label="提交人" align="center"></el-table-column>
        <el-table-column prop="log_type_text" label="汇报类型" align="center"></el-table-column>
        <el-table-column prop="createtime" label="提交时间" align="center"></el-table-column>
        <el-table-column label="汇报内容" align="center">
          <template #default="scope" >
            <div class="tttyt">{{scope.row.content}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="type == 20">
          <template #default="scope" >
              <div class="el_butto" >
                <el-button
                type="text"
                v-if="TextMoMo('workbench:worklog:read')"
                @click="HandleSee(1,scope.row)"
                >查看</el-button>
              </div>
              <div class="el_butto" >
                <el-button
                type="text"
                v-if="TextMoMo('workbench:worklog:edit')"
                @click="HandleEdit(scope.row)"
                >编辑</el-button>
              </div>
            <div class="el_butto rere" >
                <el-button
                type="text"
                class="red"
                v-if="TextMoMo('workbench:worklog:del')"
                @click="HandleDel(scope.row)"
                >删除</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="type == 10">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                type="text"
                @click="HandleSee(2,scope.row)"
                v-if="TextMoMo('workbench:worklog:read')"
              >查看
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

  
    <el-dialog :title="titleMath" v-model="addPanel" width="30%" >
      <el-form class="EditNewForm" label-width="70px" style="padding: 0px;" v-if="editPanel == 1">
        <el-form-item label="今日工作" class="EditNewFormBtns">
          <el-input v-model="Row.content" type="textarea" placeholder="请填写今日工作" ></el-input>
        </el-form-item>
        <el-form-item label="汇报类型" class="EditNewFormBtns">
          <el-select v-model="Row.log_type" placeholder="选择汇报类型" >
            <el-option v-for="item in logType" :key="item.value" :label="item.content" :value="item.value"></el-option>
          </el-select>   
        </el-form-item>
        <el-form-item label="发送给：" class="EditNewFormBtns">
          <el-select v-model="Row.send_one" multiple placeholder="选择发送人" >
            <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
          </el-select> 
        </el-form-item>
        <el-form-item label="抄送给：" class="EditNewFormBtns">
          <el-select v-model="Row.send_two" multiple placeholder="选择抄送人" >
            <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
          </el-select>  
        </el-form-item>
        <el-form-item label="上传图片" class="EditNewFormBtns">
          <el-upload
            :action="upload"
            :headers="heads"
            list-type="picture-card"
            :file-list="fileList"
            :on-success="UP_Success"
            :on-remove="HAndemove"
            :on-preview="handlePictureCardPreview">
            <i class="el-icon-plus"></i>
            <div class="elsff">上传图片</div>
          </el-upload>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtnsQuery" @click="BtnAdd()">创建</div>
          <div class="FormBtnsClose" @click="Colse()">取消</div>
        </el-form-item>
      </el-form>
      <div  v-if="editPanel == 2">
        <div style="display:flex">
          <div class="left">
            <img :src="RowList.row.staff.full_avatar" alt="" >
          </div>
          <div class="right">
            <div>{{RowList.row.staff.realname}}</div>
            <div>{{RowList.row.createtime}}</div>
            <div>{{RowList.row.content}}</div>
            <div>
              <img :src="item" alt="" v-for="item in RowList.row.imgs_url" :key="item">
            </div>
          </div>
        </div>
        <div class="two">
          <div>抄送人</div>
          <div>
            <img :src="item.full_avatar" alt="" v-for="item in RowList.send_one_list" :key="item.id">
          </div>
        </div>
        <div class="two">
          <div>发送人</div>
          <div>
            <img :src="item.full_avatar" alt="" v-for="item in RowList.send_two_list" :key="item.id">
          </div>
        </div>
        <div class="pinlun">全部评论</div>
        <el-input type="textarea" v-model="content" placeholder="请填写评论" ></el-input>
        <div class="btn">
          <div class="FormBtnsQuery" @click="BtnEdit()">保存</div>
          <div class="FormBtnsClose" @click="Colse()">取消</div>
        </div>
      </div>
      <div  v-if="editPanel == 3">
        <div style="display:flex">
          <div class="left">
            <img :src="RowList.row.staff.full_avatar" alt="" >
          </div>
          <div class="right">
            <div>{{RowList.row.staff.realname}}</div>
            <div>{{RowList.row.createtime}}</div>
            <div>{{RowList.row.content}}</div>
            <div>
              <img :src="item" alt="" v-for="item in RowList.row.imgs_url" :key="item">
            </div>
          </div>
        </div>
        <div class="two">
          <div>抄送人</div>
          <div>
            <img :src="item.full_avatar" alt="" v-for="item in RowList.send_one_list" :key="item.id">
          </div>
        </div>
        <div class="two">
          <div>发送人</div>
          <div>
            <img :src="item.full_avatar" alt="" v-for="item in RowList.send_two_list" :key="item.id">
          </div>
        </div>
        <div class="pinlun">全部评论</div>
        <div class="lll">
          <div class="divli" v-for="obj in commentList" :key="obj.id" >
            <div class="left" style="border-radius: 50%;">
              <img :src="obj.staff.full_avatar" >
            </div>
            <div class="right">
              <div>{{obj.staff.realname}}</div>
              <div>{{obj.createtime}}</div>
              <div>{{obj.content}}</div>
            </div>
          </div>
        </div>
        <div class="btn">
          <div class="FormBtnsClose" @click="Colse()">取消</div>
        </div>
      </div>
      <div  v-if="editPanel == 4">
        <div style="display:flex">
          <div class="left">
            <img :src="RowList.row.staff.full_avatar" alt="" >
          </div>
          <div class="right">
            <div>{{RowList.row.staff.realname}}</div>
            <div>{{RowList.row.createtime}}</div>
            <div>{{RowList.row.content}}</div>
            <div>
              <img :src="item" alt="" v-for="item in RowList.row.imgs_url" :key="item">
            </div>
          </div>
        </div>
        <div class="two">
          <div>抄送人</div>
          <div>
            <img :src="item.full_avatar" alt="" v-for="item in RowList.send_one_list" :key="item.id">
          </div>
        </div>
        <div class="two">
          <div>发送人</div>
          <div>
            <img :src="item.full_avatar" alt="" v-for="item in RowList.send_two_list" :key="item.id">
          </div>
        </div>
        <div class="pinlun">全部评论</div>
        <div class="lll">
          <div class="divli" v-for="obj in commentList" :key="obj.id" >
            <div class="left" style="border-radius: 50%;">
              <img :src="obj.staff.full_avatar" >
            </div>
            <div class="right">
              <div>{{obj.staff.realname}}</div>
              <div>{{obj.createtime}}</div>
              <div>{{obj.content}}</div>
            </div>
          </div>
        </div>
        <el-input type="textarea" v-model="content" placeholder="请填写评论" style="margin-top:30px"></el-input>
        <div class="btn">
          <div class="FormBtnsQuery"  @click="BtnEdit()">保存</div>
          <div class="FormBtnsClose" @click="Colse()">取消</div>
        </div>
      </div>
      
      
    </el-dialog>

    <!--全部线索类型管理-->
    <el-dialog title="管理汇报类型" v-model="dialogVisib" width="36%"> 
      <div>
        <el-table
        :data="ClueData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div>
                <el-input type="text" :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="输入内容"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="120">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete(scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="YADD" @click="CreateAdd()"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button type="primary" @click="determine()">确 定</el-button>
        </div>
      </div>  
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue"; 
export default {
  name: "basetable",
  data() {
    return {
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      upload2:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      search:{
        log_type:'',
        staff_id:'',
        to_staff_id:'',
        type: 10,
        keyword:'',
        time:{
          min:'',
          max:''
        }
      },
      Row:{
        log_type:'',
        images:[],
        content:'',
        send_one:[],
        send_two:[],
      },
      page: {currentPage: 1,pageSize: 10},
      total:'',
      titleMath:'',
      editPanel:'',
      type:10,
      content:'',
      editId:'',
      MMD:[{id:10,name:'我收到的'},{id:20,name:'我发出的'}],
      tableData:[],
      logType:[],
      staffList:[],
      StaFFList:[],
      fileList:[],
      RowList:[],
      commentList:[],
      multipleSelection:[],
      multipleSelection_id:[],
      ClueData:[],
      addPanel:false,
      dialogVisib:false
    };
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.getStaff()
    that.loadTable()
  },
  methods: {
    loadTable() {  // 获取列表数据
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/worklog/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    Chang_ses(e){
      this.search.type = e
      this.loadTable()
    },
    BtnSearch(e){
      if(e == false){
        this.search = {
          log_type:'' ,
          staff_id:'',
          to_staff_id:'',
          type: 10,
          keyword:'',
          time:{
            min:'',
            max:''
          }
        }
      }
      this.loadTable()
    },
    ReportClick(){
      this.dialogVisib = true
      this.ClueData = this.logType
    },
    changeSwitch (){
      console.log(121);
    },
    CreateAdd(){
      var that = this
      var ATT = that.ClueData
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.ClueData = ATT
    },
    haeDete(row) {     // 删除导航类别
      var that = this
      var ATT = that.ClueData
      let index = that.ClueData.findIndex(obj => obj.value === row.value)
      if (row.type == 'default') {
      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.ClueData = ATT
        console.log(that.ClueData);
      }
    },
    determine() {             // 导航确认
      var that = this 
      var ATT = that.ClueData
      axios({
        method: 'post',
        url: '/admin/company/content/edit',
        data: {
          field_key: "worklog_type", //内容标识
          content: ATT
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.dialogVisib = false
            that.getStaff()
          }
      })      
    },
    showAdd(){
      this.addPanel = true
      this.titleMath =  '创建日报'
      this.editPanel = 1
    },
    BtnAdd(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/worklog/add',
        data: {
          row: that.Row
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.addPanel = false
          that.loadTable();
        }
      }) 
    },
    HandleEdit(data){
      var that = this
      this.addPanel = true
      this.titleMath =  '编辑日报'
      this.editPanel = 2
      axios.get('/admin/workbench/worklog/read', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        that.RowList = res.data.data
      }) 
    },
    HandleSee(e,data){
      this.addPanel = true
      this.titleMath =  '查看日报'
      this.editId = data.id
      if(e == 1){
        this.editPanel = 3
      }else if(e == 2){
        this.editPanel = 4
      }
      this.kkkk()
    },
    kkkk(){
      var that = this
      axios.get('/admin/workbench/worklog/read', {  
        params: {
          id: that.editId
        }
      }).then(function (res) {
        that.RowList = res.data.data
      }) 
      axios.get('/admin/workbench/worklog/commentList', {  
        params: {
          id: that.editId
        }
      }).then(function (res) {
        that.commentList = res.data.data.comment_list
      }) 
    },
    BtnEdit(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/worklog/commentPost',
        data: {
          id:that.editId,
          row: {
            content:that.content
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.kkkk()
          that.content = ''
        }
      }) 
    },
    HandleDel(data){
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/workbench/worklog/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    getStaff(){
      var that = this
      axios.get('/admin/ajax/staffList',{   // 全部员工列表
      }).then(function (res) {
        that.StaFFList = res.data.data
      })  
      axios.get('/admin/workbench/worklog/statisticsColumns',{
      }).then(function (res) {
        that.logType = res.data.data.log_type
        that.staffList = res.data.data.staff_list
      })  
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    Colse(){
      this.addPanel = false
      this.editPanel = false
    },
    UP_Success(e) {  //图片上传成功
      var that = this
      that.Row.images.push(e.data.url)
    },
    Selection(e) {
      var that = this
      console.log(e);
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    Add_Del() {     //批量删除          
      var that = this
      if (that.multipleSelection_id == null) {   
        that.$message.warning('请勾选信息');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "primary"
        }).then(() => {
          axios({
            method: 'DELETE',
            url: '/admin/workbench/worklog/del',
            data: {
              ids: that.multipleSelection_id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable();
            }
          })
        })
      } 
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    }
  },
};
</script>

<style scoped>
.pp{
  cursor: pointer;
  color:#1890FF;
  width: 90px;
}
.left{
  width: 50px;
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 20px;
  background-color: #ccc;
}
.right div{
  color: #606266;
}
.right div:nth-child(1){
  color: #262626;
  font-size: 15px;
}
.right div:nth-child(2){
  font-size: 13px;
  margin: 5px 0;
}
.right div:nth-child(3){
  margin: 10px 0;
}
.right div:nth-child(4){
  display: flex;
}
.right div:nth-child(4) img{
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin-right: 15px;
  overflow: hidden;
}
.two{
  display:flex;
  margin: 20px 0;
  align-items: center;
}
.two div:nth-child(1){
  margin-right: 15px;
}
.two div:nth-child(2){
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
}
.pinlun{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin: 40px 0 15px;
}
.btn{
  height: 40px;
  margin-top: 20px;
}
.divli{
  display: flex;
  padding-top: 20px;
  border-top: 1px solid #eee;
  margin-bottom: 15px;
}
.lll .divli:nth-child(1){
  border-top: 0px solid #fff;
}
.lll{
  height: 200px;
  overflow-y: auto;
}
.lll::-webkit-scrollbar{
  width: 0;
}
</style>